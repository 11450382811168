import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { API_URL, protectedResources } from "../Constants";

const useFetch = (dataType = {}) => {
  const [data, setData] = useState(dataType);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    if (error) {
      const toastSettings = {
        description: error,
        status: "error",
        isClosable: true,
        // onClose: () => {
        //     toastIdRef.current = null
        // }
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    }
  }, [error, toast]);

  const fetchData = async (url, { method, body = null, signal = null }) => {
    if (!url) return;
    setLoading(true);
    if (["PUT", "DELETE"].includes(method)) {
      toastIdRef.current = toast({
        description: "loading",
        status: "loading",
        duration: null,
      });
    }
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        ...protectedResources,
        account: account,
      });
      const json = await callApi(
        url,
        { method, body },
        signal,
        tokenResponse.accessToken
      );
      toast.update(toastIdRef.current, {
        description:
          method === "PUT"
            ? "Data updated successfully"
            : "Data deleted successfully",
        status: "success",
        duration: 3000,
      });
      return json;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const tokenResponse = await instance.acquireTokenPopup({
            ...protectedResources,
            account: account,
          });
          return callApi(
            url,
            { method, body },
            signal,
            tokenResponse.accessToken
          );
        } catch (error) {
          setLoading(false);
          setError(error.message);
          instance.logout();
        }
      }
      console.log("ERROR", error);
      //This catches the error if either of the promises fails or the manual error is thrown
      setLoading(false);
      setError(error.message);
    }
  };

  const callApi = async (url, { method, body = null }, signal, accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    let options = {};
    if (body) headers.append("Content-Type", "application/json");
    if (signal) {
      options = {
        method: method,
        credentials: "include",
        headers: headers,
        body: body ? JSON.stringify(body) : null,
        signal: signal,
      };
    } else {
      options = {
        method: method,
        headers: headers,
        credentials: "include",
        body: body ? JSON.stringify(body) : null,
      };
    }
    const response = await fetch(API_URL + url, options);

    if (response.status >= 400 || response.status >= 500) {
      const json = await response.json();
      console.log("JSON: ", json);
      setError(json.Error);
      setLoading(false);
      throw new Error(json.Error);
    }

    const json = await response.json();
    setData(json);
    setLoading(false);
    setError(null);
    return json;
  };

  return [
    { data, loading, error },
    useCallback(fetchData, [account, instance, toast]),
  ];
};

export default useFetch;
